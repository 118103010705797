import React from "react";
import {
	FormInput,
	FormDropdown,
	FormCheckbox,
	FormArray,
	FormTextarea,
} from ".";
const helper = require("./helper");

export default class Form extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			formData: this.props.schema.properties,
		};
		// init data back to parent
		this.props.onChange(helper.stripAllToValue(this.props.schema.properties));
	}

	renderFormItems = () => {
		let items = [];
		let formData = this.props.schema.properties;

		Object.keys(formData)
			.sort((k1, k2) => formData[k2].sort ?? 0 - formData[k1].sort ?? 0)
			.forEach((key) => {
				formData[key].onChange = (value) => {
					let data = formData;
					data[key].value = value;
					this.setState({ formData: data });
					this.props.onChange(helper.stripAllToValue(data));
				};

				switch (formData[key].type) {
					case "object":
						items.push(
							<Form
								key={key}
								schema={formData[key]}
								// onChange={() => this.props.onChange(formData)}
								onChange={formData[key].onChange}
							/>
						);
						break;
					case "string":
						items.push(
							<FormInput
								key={key}
								value={formData[key].value}
								label={formData[key].title}
								placeholder={key}
								isDisabled={formData[key].isDisabled}
								onChange={formData[key].onChange}
							/>
						);
						break;
					case "text":
						items.push(
							<FormTextarea
								key={key}
								value={formData[key].value}
								label={formData[key].title}
								placeholder={key}
								isDisabled={formData[key].isDisabled}
								onChange={formData[key].onChange}
							/>
						);
						break;
					case "dropdown":
						items.push(
							<FormDropdown
								key={key}
								value={formData[key].value}
								options={formData[key].enum}
								label={formData[key].title}
								placeholder={key}
								isDisabled={formData[key].isDisabled}
								onChange={formData[key].onChange}
							/>
						);
						break;
					case "boolean":
						items.push(
							<FormCheckbox
								key={key}
								value={formData[key].value}
								label={formData[key].title}
								placeholder={key}
								isDisabled={formData[key].isDisabled}
								onChange={formData[key].onChange}
							/>
						);
						break;
					case "array":
						items.push(
							<FormArray
								key={key}
								title={formData[key].title}
								label={key}
								items={formData[key].items}
								value={formData[key].value}
								onChange={formData[key].onChange}
							/>
						);
						break;
					default:
						items.push(
							<FormInput
								key={key}
								value={formData[key].value}
								label={formData[key].title}
								placeholder={key}
								isDisabled={formData[key].isDisabled}
								onChange={formData[key].onChange}
							/>
						);
				}
			});
		return items;
	};

	render() {
		return (
			<div>
				{this.props.header && <h1 className="title">{this.props.header}</h1>}
				<div className="title is-3">
					{this.props.schema.title}
					<div className="subtitle is-5">{this.props.schema.description}</div>
				</div>
				<div className="control">
					{this.renderFormItems()}
					<div>{this.props.children}</div>
				</div>
			</div>
		);
	}
}
