import React from "react";
import { Form, FormInput } from ".";

export default class FormArray extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			formItemList: [],
			items: this.props.value,
		};
	}

	cleanItemLoop = (data) => {
		Object.keys(data).forEach((key) => {
			if (key === "properties") {
				Object.keys(data[key]).forEach((key2) => {
					this.cleanItem(data[key][key2]);
				});
			}
		});
		return data;
	};

	cleanItem = (item) => {
		switch (item.type) {
			case "object":
				this.cleanItemLoop(item);
				break;
			case "string":
				item.value = "";
				break;
			case "dropdown":
				item.value = item.enum[0];
				break;
			case "boolean":
				item.value = false;
				break;
			case "array":
				item.items.forEach((x) => {
					if (x.type === "object") {
						this.cleanItemLoop(x);
					}
					if (x.type === "string") {
						this.cleanItem(x);
					}
				});
				break;
			default:
				break;
		}
	};

	mapPropertiesToValue = (object, values) => {
		if (object.properties === undefined || values === undefined) {
			return;
		}
		Object.keys(object.properties).forEach((key) => {
			if (values[key] !== undefined) {
				object.properties[key].value = values[key];
			}
		});
	};

	renderItems = (items) => {
		let formItemList = [];
		for (let i = 0; i < items.length; i++) {
			let copied = JSON.parse(JSON.stringify(this.props.items[0]));
			copied.value = this.props.value[i];
			// render this from value, put the clean up on the on change in form.
			if (copied.type === "string") {
				formItemList.push(
					<FormInput
						key={copied.title}
						// value={/*shouldn't need this*/}
						label={""}
						placeholder={""}
						isDisabled={false}
						onChange={(value) => {
							items[i].value = value;
						}}
					/>
				);
			}
			if (copied.type === "object") {
				this.mapPropertiesToValue(copied, copied.value);
				formItemList.push(
					<Form
						key={copied.title}
						schema={copied}
						onChange={(data) => {
							items[i].value = data;
							let returnArray = [];
							items.forEach((item) => {
								if (item.hasOwnProperty("value")) {
									returnArray.push(item.value);
								} else {
									returnArray.push(item);
								}
							});
							this.props.onChange(returnArray);
						}}
					/>
				);
			}
		}
		this.setState({ formItemList: formItemList });
	};

	onAddClick = async () => {
		// performs a deep copy
		let items = JSON.parse(JSON.stringify(this.props.value));
		for (let item of this.props.items) {
			let copied = JSON.parse(JSON.stringify(item));
			let newItem = this.cleanItemLoop(copied);
			items.push(newItem);
		}
		await this.props.onChange(items);
		this.renderItems(items);
	};

	onRemoveClick = async () => {
		let items = JSON.parse(JSON.stringify(this.props.value));
		items.pop();
		await this.props.onChange(items);
		this.renderItems(items);
	};

	render() {
		const { label, title } = this.props;
		return (
			<div className="form-array">
				<div className="subtitle is-5">{title}</div>
				<div className="control">
					<div className="box">
						<label className="label">{label}</label>
						<div>{this.state.formItemList}</div>
						<div
							className="add-button button is-success is-pulled-right"
							onClick={() => this.onAddClick()}
						>
							Add
						</div>
						<div
							className="remove-button button is-danger is-pulled-right"
							onClick={() => this.onRemoveClick()}
						>
							Remove
						</div>
					</div>
				</div>
			</div>
		);
	}
}
