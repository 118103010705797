import React from "react";
import { withRouter } from "react-router";
import services from "../services";

export default (Component) =>
	withRouter(
		class RouteProtector extends React.Component {
			constructor() {
				super();
				this.state = {
					waiting: true,
				};
			}

			componentDidMount() {
				const result = services.auth.isUserLoggedIn();
				if (result !== true) {
					if (this.props.location.pathname !== "/") {
						this.props.history.push("/");
						return;
					}
					this.props.history.push("/login");
				}
			}

			render() {
				return <Component {...this.props} />;
			}
		}
	);
