import { Table } from "../../../components/Table";
import React, { useEffect, useState } from "react";
import { ProviderEditor } from "./ProviderEditor";
import { voidFn } from "../../../utils/voidFn";

const Header = [
	"Action",
	"Network",
	"Npi",
	"Tax Id",
	"Practice Name",
	"Title",
	"First Name",
	"Middle Name",
	"Last Name",
	"Suffix",
	"Degrees",
	"Gender",
	"Address1",
	"Address2",
	"City",
	"State",
	"Zip Code",
	"County",
	"Primary Specialty",
	"Medicare Specialty Code",
	"Sub Specialty",
	"Medicare Subspecialty Code",
	"Source Specialty",
	"Second Source Specialty",
	"Is Pcp",
	"Is Pediatrician",
	"Is Surgeon",
	"Is Geriatric",
	"Publish",
	"Start Date",
	"End Date",
	"Termination Date",
	"Taxonomy Code",
	"Practice Phone",
	"Practice Fax",
	"Practice Webpage URL",
	"Practice Email",
	"Payment Name",
	"Billing Address1",
	"Billing Address2",
	"Billing City",
	"Billing State",
	"Billing ZipCode",
	"Billing PhoneNumber",
	"Billing Fax",
	"Billing Email",
	"Panel Open",
	"Panel Slots",
	"Min Age",
	"Max Age",
	"Hospital Privileges",
	"Board Certified",
	"Board Name",
	"Languages",
	"Emr Type",
	"Emr Version",
	"Location ID",
	"Base Location ID",
	"Roster ID",
	"Lat",
	"Long",
];

const InitialProvidersData = {
	header: Header,
	rows: [],
};

export const ProvidersTable = ({ providers, onUpdate = voidFn }) => {
	const [data, setData] = useState(InitialProvidersData);

	useEffect(() => {
		const rows = providers.map((r) => [
			<ProviderEditor provider={r} onUpdate={onUpdate} />,
			r.network,
			r.npi,
			r.taxId,
			r.practiceName,
			r.title,
			r.firstName,
			r.middleName,
			r.lastName,
			r.suffix,
			r.degree,
			r.gender,
			r.address1,
			r.address2,
			r.city,
			r.state,
			r.zipCode,
			r.county,
			r.primarySpecialty,
			r.medicareSpecialtyCode,
			r.subSpecialty,
			r.medicareSubSpecialtyCode,
			r.sourceSpecialty,
			r.secondSourceSpecialty,
			String(r.isPcp),
			String(r.isPediatrician),
			String(r.isSurgeon),
			String(r.isGeriatric),
			r.publish,
			r.startDate,
			r.endDate,
			r.terminationDate,
			r.taxonomyCode,
			r.practicePhone,
			r.practiceFax,
			r.practiceWebpageURL,
			r.practiceEmail,
			r.paymentName,
			r.billingAddress1,
			r.billingAddress2,
			r.billingCity,
			r.billingState,
			r.billingZipCode,
			r.billingPhoneNumber,
			r.billingFax,
			r.billingEmail,
			r.panelOpen,
			r.panelSlots,
			r.minAge,
			r.maxAge,
			r.hospitalPrivlidges,
			r.boardCertified,
			r.boardName,
			r.languages,
			r.emrType,
			r.emrVersion,
			r.locationID,
			r.baseLocationID,
			r.rosterID,
			r.lat,
			r.long,
		]);

		setData({
			header: Header,
			rows,
		});
	}, [providers, onUpdate]);

	return <Table data={data} pageSize={25} />;
};
