import React, { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import DataGrid, {
	SelectColumn,
	TextEditor,
	SelectCellFormatter,
} from "react-data-grid";
import { exportToCsv, exportToXlsx, exportToPdf } from "./utils/exportUtils";
import { tryRunAsyncFactory } from "../../utils/tryRunAsyncFactory";
import api from "../../services/api";

const dateFormatter = new Intl.DateTimeFormat(navigator.language);

const stopPropagation = (event) => {
	event.stopPropagation();
};

const rowKeyGetter = (row) => {
	return row.id;
};

const createRows = () => {
	const rows = [];
	return rows;
};

// TODO:
const getComparator = (sortColumn) => {
	switch (sortColumn) {
		case "assignee":
		case "title":
		case "client":
		case "area":
		case "country":
		case "contact":
		case "transaction":
		case "account":
		case "version":
			return (a, b) => {
				return a[sortColumn].localeCompare(b[sortColumn]);
			};
		case "available":
			return (a, b) => {
				return a[sortColumn] === b[sortColumn] ? 0 : a[sortColumn] ? 1 : -1;
			};
		case "id":
		case "progress":
		case "startTimestamp":
		case "endTimestamp":
		case "budget":
			return (a, b) => {
				return a[sortColumn] - b[sortColumn];
			};
		default:
			throw new Error(`unsupported sortColumn: "${sortColumn}"`);
	}
};

const generateCentivoColumns = async (type = "provider", isStatus = true) => {
	let columns = [];
	console.log("TYPE:", type);
	// TODO: make this more correct
	if (type === "provider-exported") {
		type = "provider";
	}
	if (type === "facility-exported") {
		type = "facility";
	}
	const columnResults = await api.getIngestionModel(type);
	columns.push({
		key: "id",
		name: "Id",
		width: 100,
	});
	if (isStatus === true) {
		columns.push({
			key: "status",
			name: "Status",
			width: 100,
		});
	}
	for (let item of columnResults) {
		// console.log(item);
		columns.push({
			key: item,
			name: item,
			width: 100,
			// frozen: false,
			// resizable: true,
			editor: TextEditor,
		});
	}
	// console.log("COLUMNS:", columns);
	return columns;
};

/******************** FUNCTION DECLERATION CONSTRUCTOR METHODS ********************/

function TimestampFormatter({ timestamp }) {
	return <>{dateFormatter.format(timestamp)}</>;
}

function ImportButton({ onImport }) {
	const [importing, setImporting] = useState(false);
	return (
		<input
			disabled={importing}
			className="input-file is-margin-right"
			type="file"
			id="file"
			accept="*.*"
			onChange={async (e) => {
				setImporting(true);
				await onImport(e.target.files[0]);
				// await handleFileChosen(e.target.files[0]);
				setImporting(false);
			}}
		/>
	);
}

function ClearButton({ onClear, children }) {
	const [clearing, setClearing] = useState(false);
	return (
		<button
			className="is-margin-right"
			disabled={clearing}
			onClick={async () => {
				setClearing(true);
				onClear();
				setClearing(false);
			}}
		>
			{clearing ? "Clearing" : children}
		</button>
	);
}

function IngestButton({ onIngest, children }) {
	const [ingesting, setIngesting] = useState(false);
	return (
		<button
			className="is-margin-right"
			disabled={ingesting}
			onClick={async () => {
				setIngesting(true);
				await onIngest();
				setIngesting(false);
			}}
		>
			{ingesting ? "Ingesting" : children}
		</button>
	);
}

function ExportButton({ onExport, children }) {
	const [exporting, setExporting] = useState(false);
	return (
		<button
			className="is-margin-right"
			disabled={exporting}
			onClick={async () => {
				setExporting(true);
				await onExport();
				setExporting(false);
			}}
		>
			{exporting ? "Exporting" : children}
		</button>
	);
}

export default function CommonFeatures() {
	const [rows, setRows] = useState(createRows);
	const [sortColumns, setSortColumns] = useState([]);
	const [selectedRows, setSelectedRows] = useState(() => new Set());
	const [columns, setColumns] = useState([]);
	const [selectedType, setSelectedType] = useState("provider");
	const [selectedTab, setSelectedTab] = useState("ingestion");
	const [lastEvalKey, setLastEvalKey] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [parseResult, setParseResult] = useState(null);
	const tryRunAsync = tryRunAsyncFactory(setIsLoading);

	useEffect(() => {
		let active = true;
		load();
		return () => {
			active = false;
		};

		async function load() {
			const columnResults = await generateCentivoColumns(selectedType);
			setColumns(columnResults);
			if (!active) {
				return;
			}
		}
	}, [columns.length]);

	const summaryRows = useMemo(() => {
		const summaryRow = {
			id: "total_0",
			totalCount: rows.length,
			yesCount: rows.filter((r) => r.available).length,
		};
		return [summaryRow];
	}, [rows]);

	const sortedRows = useMemo(() => {
		if (sortColumns.length === 0) return rows;

		const sortedRows = [...rows];
		sortedRows.sort((a, b) => {
			for (const sort of sortColumns) {
				const comparator = getComparator(sort.columnKey);
				const compResult = comparator(a, b);
				if (compResult !== 0) {
					return sort.direction === "ASC" ? compResult : -compResult;
				}
			}
			return 0;
		});
		return sortedRows;
	}, [rows, sortColumns]);

	/******************** ADDED LOGIC CLEAN UP BELOW ********************/

	const reloadColumns = async (type) => {
		setSelectedType(type);
		setColumns(await generateCentivoColumns(type));
	};

	const generateRowData = (results) => {
		let rows = [];
		let i = 0;
		for (let item of results) {
			item.id = i++;
			for (let key of Object.keys(item)) {
				if (item[key] != null) {
					item[key] = item[key].toString();
				}
			}
			rows.push(item);
		}
		setRows(rows);
	};

	const handleParseFile = async (type, selectedFile) => {
		await tryRunAsync(async () => {
			setParseResult(null);
			const { resultUrl } = await api.parseIngestionFile(
				selectedFile.name,
				selectedFile.data,
				type
			);
			// toast.success("Document is acceptable");
			const jsonResultList = await api.downloadS3Url(resultUrl);
			setParseResult(jsonResultList);
			generateRowData(jsonResultList);
			let missingPAC = jsonResultList.find((row) => {
				return (
					row.networkParentCode == null ||
					row.networkParentCode == undefined ||
					row.networkParentCode == ""
				);
			});
			let missingTin = jsonResultList.find((row) => {
				return !row.tin;
			});
			let missingNpi = jsonResultList.find((row) => {
				return !row.npi;
			});
			if (missingPAC) {
				toast.error("Error: Network Parent Code is missing!");
			} else if (type === "veda-facility" && missingTin) {
				toast.error("Error: TIN is missing or incorrect length!");
			} else if (type === "veda-provider" && missingNpi) {
				toast.error("Error: NPI is missing or incorrect length!");
			} else {
				toast.success("Document is acceptable");
			}
		});
	};

	const onImport = async (file) => {
		const data = await new Promise((resolve, reject) => {
			const fr = new FileReader();
			fr.onload = () => {
				resolve(fr.result);
			};
			fr.onerror = reject;
			fr.readAsArrayBuffer(file);
		});
		await handleParseFile(selectedType, { name: file.name, data: data });
	};

	const onIngest = async () => {
		const results = await api.ingestRecordsBatch(rows, selectedType);
		const newRows = [];
		for (let result of results) {
			let row = result.item;
			row.status = result.status;
			newRows.push(row);
		}
		generateRowData(newRows);
	};

	const onClear = () => {
		let newRows = [];
		for (let row of rows) {
			if (row.status != null && row.status.toLowerCase() === "success") {
				continue;
			}
			newRows.push(row);
		}
		generateRowData(newRows);
	};

	const isActive = (tab) => (tab ? "is-active" : "");
	const handleTabClick = (tab) => {
		setSelectedTab(tab);
		if (tab === "flagged") {
			api.getFlaggedRecords(selectedType).then((value) => {
				setLastEvalKey(value.lastEvalKey);
				generateRowData(value.items);
			});
			return;
		}
		setRows([]);
	};

	const gridElement = (
		<DataGrid
			style={{ height: "100%", maxHeight: "700px", contain: "none" }}
			rowKeyGetter={rowKeyGetter}
			columns={columns}
			rows={sortedRows}
			defaultColumnOptions={{
				sortable: true,
				resizable: true,
			}}
			selectedRows={selectedRows}
			onSelectedRowsChange={setSelectedRows}
			onRowsChange={setRows}
			sortColumns={sortColumns}
			onSortColumnsChange={setSortColumns}
			summaryRows={summaryRows}
			className="fill-grid"
		/>
	);

	const typeSelectList = (
		<select
			className="is-margin-right"
			style={{ marginTop: "10px" }}
			onChange={async (option) => {
				setRows([]);
				await reloadColumns(option.target.value);
			}}
		>
			<option value="provider">Provider Type</option>
			<option value="provider-exported">Provider Exported Type</option>
			<option value="facility">Facility Type</option>
			<option value="facility-exported">Facility Exported Type</option>
			<option value="veda-provider">Veda Provider</option>
			<option value="veda-facility">Veda Facility</option>
		</select>
	);

	return (
		<div style={{ padding: "20px" }}>
			<div className="container mt-2">
				<div className="tabs is-toggle is-fullwidth is-centered">
					<ul>
						<li className={isActive(selectedTab === "ingestion")}>
							<a onClick={() => handleTabClick("ingestion")}>Ingestion</a>
						</li>
						<li className={isActive(selectedTab === "flagged")}>
							<a onClick={() => handleTabClick("flagged")}>Flagged</a>
						</li>
					</ul>
				</div>
			</div>
			<div className="toolbarClassname">
				<ClearButton onClear={() => onClear()}>Clear Status</ClearButton>
				{typeSelectList}
				<ImportButton onImport={(data) => onImport(data)}>
					Import Ingestion File
				</ImportButton>
				<IngestButton onIngest={() => onIngest()}>Ingest File</IngestButton>
				<ExportButton
					onExport={() => exportToCsv(gridElement, "IngestionExport.csv")}
				>
					{" "}
					Export to CSV
				</ExportButton>
				<ExportButton
					onExport={() => exportToXlsx(gridElement, "IngestionExport.xlsx")}
				>
					{" "}
					Export to XSLX
				</ExportButton>
				<ExportButton
					onExport={() => exportToPdf(gridElement, "IngestionExport.pdf")}
				>
					{" "}
					Export to PDF
				</ExportButton>
			</div>
			{gridElement}
		</div>
	);
}
