import React, { useEffect, useMemo, useState } from "react";
import { Table } from "../../../components/Table";
import { isoStringToDateTime } from "../../../utils/timeUtil";
import sortBy from "lodash.sortby";
import { IngestionLogSearchbar } from "./IngestionLogSearchbar";
import { nullable } from "../../../utils/nullable";

const Header = [
	"Id",
	"File",
	"Status", // Type
	"No. Items",
	"Start Date",
	"End Date",
	"Level",
];

const InitialData = {
	header: Header,
	rows: [],
};

const IngestionTypeMap = {
	INGESTION_SUCCESS: { cx: "is-success", label: "Success" },
	INGESTION_FAILURE: { cx: "is-error", label: "Failure" },
};

const LogType = ({ type }) => {
	const { cx, label } = useMemo(
		() => IngestionTypeMap[type] || { cx: "is-warning", label: "Unknown" },
		[type]
	);

	return <span className={`tag ${cx}`}>{label}</span>;
};

export const IngestionLogTable = ({ logs, dateTo, dateFrom, onDateChange }) => {
	const [logData, setLogData] = useState(InitialData);
	const [filteredLogs, setFilteredLogs] = useState(InitialData);
	const [searchTerm, setSearchTerm] = useState("");

	useEffect(() => {
		const sortedLogs = sortBy(logs, "startDate").reverse();
		const rows = sortedLogs.map((r) => [
			r.id,
			r.fileLoaded,
			<LogType type={r.type} />,
			r.length ? r.length.toString(10) : 0,
			nullable(r.startDate, isoStringToDateTime),
			nullable(r.endDate, isoStringToDateTime),
			r.level,
		]);

		setLogData({
			header: Header,
			rows,
		});
	}, [logs]);

	useEffect(() => {
		if (!searchTerm) {
			setFilteredLogs(logData);
			return;
		}

		const term = searchTerm.toLowerCase();
		const filteredRows = logData.rows.filter(
			(row) =>
				row &&
				row.findIndex((cel) => {
					if (!cel) return false;
					if (cel.type === LogType) {
						return cel.props.type.toLowerCase().indexOf(term) !== -1;
					}
					return cel.toLowerCase().indexOf(term) !== -1;
				}) !== -1
		);

		const result = {
			header: logData.header,
			rows: [...filteredRows],
		};

		setFilteredLogs(result);
	}, [searchTerm, logData]);

	const handleOnSearch = (term) => {
		setSearchTerm(term);
	};

	return (
		<>
			<IngestionLogSearchbar
				onSearch={handleOnSearch}
				dateTo={dateTo}
				dateFrom={dateFrom}
				onDate={onDateChange}
			/>
			<Table data={filteredLogs} pageSize={25} />
		</>
	);
};
