import React, { useCallback, useEffect, useMemo, useState } from "react";
import RotateLoader from "react-spinners/RotateLoader";
import services from "../../services";
import styles from "../../styles";
import { tryRunAsyncFactory } from "../../utils/tryRunAsyncFactory";
import { isoStringToDateTime } from "../../utils/timeUtil";
import { NetworksTable } from "./NetworksTable";
import { toast } from "react-toastify";
import { useMounted } from "../../hooks/useMounted";
import { toBooleanString } from "../../utils/boolean";
import { IconButton } from "../../components/IconButton";
import { NetworkEditor } from "./Editor";

/*
    Network Object Response

    "currentExecutionId": "bb127fec-b7d9-429b-bde6-16b231b9b956",
    "networkCode": "serviceRecord#serviceRecord#wakeforest",
    "partitionUpdateRequired": false,
    "itemCount": "N/A",
    "currentExecutionDate": "2021-08-09T20:00:07.933Z",
    "created": "2021-08-09T20:00:07.933Z",
    "providerDomainNetwork": "serviceRecord#wakeforest",
    "pricingStrategy": "N/A",
    "s3CacheLocation": "providerstorage-provideringestionroutera81abe1a-a8gl2x8im16m/athena-cache",
    "lastExecutionId": null,
    "lastExecutionDate": null,
    "newQueryNeeded": false,
    "s3CacheFileName": "serviceRecord#wakeforest.json",
    "id": "serviceRecord#serviceRecord#wakeforest",
    "modified": "2021-08-09T20:00:07.933Z"
 */

export default () => {
	const [isLoading, setIsLoading] = useState(false);
	const [networksData, setNetworksData] = useState([]);
	const [selectedNetwork, setSelectedNetwork] = useState(null);
	const [modalOpen, setModalOpen] = useState(false);
	const isMounted = useMounted();

	const tryRunAsync = useMemo(
		() =>
			tryRunAsyncFactory(setIsLoading, (e) => {
				toast.error(e);
			}),
		[]
	);

	const fetchAllNetworks = useCallback(async () => {
		await tryRunAsync(async () => {
			const data = await services.api.getAllNetworks();
			isMounted && setNetworksData(data);
		});
	}, [isMounted, tryRunAsync]);

	const createOrUpdateNetwork = useCallback(
		async (data) => {
			await tryRunAsync(async () => {
				try {
					if (selectedNetwork) {
						await services.api.updateNetwork(data);
						toast.success("Successfully updated Network");
					} else {
						await services.api.createNetwork(data);
						toast.success("Successfully created Network");
					}
				} catch (err) {
					console.error(err.response.data.error);
					throw err.response.data.error.message;
				}
				if (!isMounted) return;
				setModalOpen(false);
				setSelectedNetwork(null);
				await fetchAllNetworks();
			});
		},
		[isMounted, tryRunAsync, fetchAllNetworks, selectedNetwork]
	);

	useEffect(() => {
		isMounted && fetchAllNetworks();
	}, [isMounted, fetchAllNetworks]);

	const tableData = useMemo(() => {
		if (!networksData) return [];

		const isServiceRecord = (r) =>
			r.networkCode.toLowerCase().startsWith("servicerecord#");

		const header = [
			"Action",
			"Provider Domain Network",
			"JV Network Code",
			"Network Code",
			"Created",
			"Modified",
			"Item Count",
			"Pricing Strategy",
			"Partition Update Required",
			"New Query Needed",
			"Current Execution Date",
			"Current Execution ID",
			"Last Execution Date",
			"Last Execution ID",
			"S3 Cache Location",
			"S3 Cache File",
		];

		const rows = networksData
			.map((r) => [
				isServiceRecord(r) ? null : (
					<IconButton
						onClick={() => handleOnEdit(r)}
						title="Edit"
						iconName="fa-edit"
					/>
				),
				r.providerDomainNetwork,
				r.networkCode,
				r.planNetworkCodes,
				isoStringToDateTime(r.created),
				isoStringToDateTime(r.modified),
				r.itemCount,
				r.pricingStrategy,
				toBooleanString(r.partitionUpdateRequired),
				toBooleanString(r.newQueryNeeded),
				isoStringToDateTime(r.currentExecutionDate),
				r.currentExecutionId,
				isoStringToDateTime(r.lastExecutionDate),
				r.lastExecutionId,
				r.s3CacheLocation,
				r.s3CacheFileName,
			])
			.sort((a, b) => a[1].localeCompare(b[1]));
		return { header, rows };
	}, [networksData]);

	const handleOnNew = () => {
		setSelectedNetwork(null);
		setModalOpen(true);
	};

	const handleOnEdit = (network) => {
		setSelectedNetwork(network);
		setModalOpen(true);
	};

	const handleEditorOnCancel = () => {
		setModalOpen(false);
	};

	const handleEditorOnSubmit = (data) => {
		console.debug("Submitting Network Data", data);
		createOrUpdateNetwork(data);
	};

	return (
		<div style={{ position: "relative" }}>
			<RotateLoader
				css={styles.override}
				size={30}
				margin={30}
				color="#ED008B"
				loading={isLoading}
			/>
			<NetworkEditor
				open={modalOpen}
				onCancel={handleEditorOnCancel}
				onSubmit={handleEditorOnSubmit}
				network={selectedNetwork}
				existingNetworks={networksData}
				isLoading={isLoading}
			/>
			<div id="topDivider" className="container is-margin-top">
				<NetworksTable networks={tableData} onNew={handleOnNew} />
			</div>
		</div>
	);
};
