/** @jsx jsx */
// eslint-disable-next-line
import React, { useState } from "react";
import { hover } from "../../styles/hover";
import { voidFn } from "../../utils/voidFn";
import { jsx, css } from "@emotion/react";

const container = css`
	white-space: nowrap;
	font-size: 12px;
	border: 1px solid lightgrey;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
`;

export const TablePager = ({
	pages,
	totalCount,
	onPageChange,
	pageSize: _pageSize,
	onPageSizeChange = voidFn,
}) => {
	const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = useState(_pageSize);

	const handleClick = (type) => () => {
		const newPage =
			type === "+" ? Math.min(pages, page + 1) : Math.max(0, page - 1);
		setPage(newPage);
		onPageChange(newPage);
	};

	const handlePageSizeChange = ({ target }) => {
		const value = target.value.replace(/\D+/, "");
		const size = value ? Math.min(parseInt(value, 10), 250) : value;
		setPageSize(size);
	};

	const handlePageSizeBlur = () => {
		if (pageSize) {
			onPageSizeChange(pageSize);
		} else {
			setPageSize(_pageSize);
		}
	};

	return (
		<div
			css={container}
			className="is-flex is-flex-direction-row is-justify-content-space-between is-fullwidth is-align-items-center is-unselectable mt-2"
		>
			<div
				css={hover}
				className="mr-2 is-clickable is-flex-grow-0 pl-3 p-2"
				onClick={handleClick("-")}
			>
				<i className="fas fa-angle-left fa-2x" />
			</div>
			<div className="is-flex is-flex-direction-row is-align-items-center is-justify-content-space-evenly is-full-width">
				<div className="is-flex-grow-1 is-flex is-flex-direction-column">
					<small>
						Page {page + 1} from {pages + 1}
					</small>
					<small>
						Items {page * pageSize + 1} to{" "}
						{Math.min((page + 1) * pageSize, totalCount)}
					</small>
				</div>

				<p className="is-flex-grow-1">Total Items: {totalCount}</p>
				<input
					title="Page Size"
					className="input is-small mr-1"
					style={{ width: "48px" }}
					type="text"
					placeholder="Page Size"
					onChange={handlePageSizeChange}
					onBlur={handlePageSizeBlur}
					value={pageSize}
				/>
				<p className="">Items per page</p>
			</div>
			<div
				css={hover}
				className="ml-2 is-clickable is-flex-grow-0 p-2 pr-4 has-background-color"
				onClick={handleClick("+")}
			>
				<i className="fas fa-angle-right fa-2x" />
			</div>
		</div>
	);
};
