import React from "react";
import { Switch, Route } from "react-router-dom";
import { Home, Login, Data, NetworkConfig, Tools, Ingestion } from "../views";
import { RouteProtector as withAuthorization } from "../hoc";

const Router = () => (
	<div>
		<Switch>
			<Route exact path="/" component={withAuthorization(Home)} />
			<Route exact path="/login" component={Login} />
			<Route exact path="/doctordata" component={withAuthorization(Data)} />
			<Route exact path="/doctordatanoauth" component={Data} />
			<Route
				exact
				path="/networkconfig"
				component={withAuthorization(NetworkConfig)}
			/>
			<Route exact path="/tools" component={withAuthorization(Tools)} />
			<Route exact path="/ingestion" component={withAuthorization(Ingestion)} />
		</Switch>
	</div>
);
export default Router;
