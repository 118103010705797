export const stripAllToValue = (data) => {
	let newObject = {};
	Object.keys(data).forEach((key) => {
		newObject[key] = data[key].value;
	});
	return newObject;
};

export const stripToValue = (data) => {
	let newObject = {};
	Object.keys(data).forEach((key) => {
		newObject[key] = data[key].value;
	});
	console.log(newObject);
	return {
		value: newObject,
	};
};

export const stripAllAttributesToValue = (data) => {
	// TODO traverse a whole tree of properties with a value and set them to the value
	let newObject = {};
	Object.keys(data).forEach((key) => {
		console.log(key);
		console.log(data[key] !== undefined);
		if (data[key] !== undefined) {
			if (data[key].hasOwnProperty("value")) {
				newObject[key] = data[key].value;
			}

			if (typeof data[key] === "object" && Object.keys(data[key]).length > 0) {
				//&& data[key].hasOwnProperty('value')) {
				console.log("TYPE OF KEY BLAH BLAH:");
				console.log(data[key]);
			}

			if (Array.isArray(data[key])) {
				newObject[key] = stripAllAttributesToValue(data[key]);
			} else {
				console.log(key);
				newObject[key] = data[key];
			}
			return;
		}
		// console.log(key)
		// console.log(data[key].value)
		if (key === "value") {
			newObject[key] = data[key].value;
		}
		if (data[key].hasOwnProperty("value")) {
			newObject[key] = data[key].value;
		}
	});
	return newObject;
};
