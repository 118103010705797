/** @jsx jsx */
import { hover } from "../styles/hover";
import { jsx } from "@emotion/react";
import { voidFn } from "../utils/voidFn";
import { disabled } from "../styles/disabled";

const EnabledBigButton = ({ onClick, children }) => (
	<div
		className={"tile is-parent is-align-center is-clickable px-2 py-2"}
		onClick={onClick}
	>
		<article
			css={hover}
			className={"tile is-child box article-button px-3 py-4"}
		>
			{children}
		</article>
	</div>
);
const DisabledBigButton = ({ children }) => (
	<div
		css={disabled}
		className={"tile is-parent is-align-center is-light px-2 py-2"}
	>
		<article className={"tile is-child box article-button px-3 py-4"}>
			{children}
		</article>
	</div>
);

export const BigButton = ({ onClick = voidFn, disabled = false, children }) => {
	const content =
		typeof children === "string" ? (
			<p className="subtitle">{children}</p>
		) : (
			children
		);

	return disabled ? (
		<DisabledBigButton>{content}</DisabledBigButton>
	) : (
		<EnabledBigButton onClick={onClick}>{content}</EnabledBigButton>
	);
};
