import React from "react";
import { Searchbar } from "../../../components/Searchbar";
import { GenericDatePicker } from "../../GenericDatePicker";

export const IngestionLogSearchbar = ({
	dateTo,
	dateFrom,
	onSearch,
	onDate,
}) => (
	<div className="container">
		<div className="is-flex is-flex-direction-row is-justify-content-flex-center is-align-items-center">
			<GenericDatePicker
				dateTo={dateTo}
				dateFrom={dateFrom}
				onChange={onDate}
			/>
			<Searchbar
				onSearch={onSearch}
				placeholder={`Enter text to search logs`}
			/>
		</div>
	</div>
);
