import React, { useState } from "react";
import services from "../../../services";
import { EditItemContainer } from "../EditItemContainer";
import { tryRunAsyncFactory } from "../../../utils/tryRunAsyncFactory";
import { toast } from "react-toastify";
import { voidFn } from "../../../utils/voidFn";

export const ProviderEditor = ({ provider, onUpdate = voidFn }) => {
	const [, setIsLoading] = useState(false);

	const handleSubmit = async (data) => {
		try {
			setIsLoading(true);
			await services.api.updateCarepointRecord(data).then((res) => {
				if (res.message && res.message.includes("406")) {
					toast.error(
						"We currently don't support editing ingestion data for non whitelisted users!"
					);
					return;
				}
				toast.success("Provider edited successfully");
			});
		} catch (e) {
			console.log(e);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className="is-full-width">
			<EditItemContainer
				title="Doctor"
				data={provider}
				onSubmit={handleSubmit}
			/>
		</div>
	);
};
