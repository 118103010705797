import axios from "axios";
import auth from "./auth";
import jsUtils from "./utils/jsUtils";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const login = async (username, password) => {
	const response = await axios.post(`${API_ENDPOINT}/login`, {
		username,
		password,
	});
	if (response.status !== 200) {
		return null;
	}
	return response.data.authToken;
};

const verifyAccount = async (username, accessCode, password) => {
	const response = await axios.post(`${API_ENDPOINT}/verify`, {
		username,
		accessCode,
		password,
	});
	if (response.status !== 200) {
		return null;
	}
	return response.data;
};

const sendAccessCode = async (username) => {
	const response = await axios.post(`${API_ENDPOINT}/sendAccessCode`, {
		username,
	});
	if (response.status !== 200) {
		return null;
	}
	return response.data;
};

const logout = async (token) => {
	const response = await axios.post(`${API_ENDPOINT}/logout`, {
		token,
	});
	if (response.status !== 200) {
		return null;
	}
	return response.data;
};

const validateFile = async (fileName, fileData, type = "PROVIDER") => {
	const getPresignedUrlResponse = await axios.get(
		`${API_ENDPOINT}/getPresignedUrl`,
		{
			headers: {
				Authorization: `${auth.getToken()}`,
			},
			params: {
				objectName: fileName,
			},
		}
	);
	if (getPresignedUrlResponse.status !== 200) {
		return null;
	}
	const presignedUrl = getPresignedUrlResponse.data.url;

	const putObjectResponse = await axios.put(presignedUrl, fileData, {
		headers: {
			"Content-Type": "application/octet-stream",
		},
	});
	if (putObjectResponse.status !== 200) {
		return null;
	}

	const validateFileResponse = await axios.post(
		`${API_ENDPOINT}/validateFile`,
		{
			type: type,
			fileName: fileName,
		},
		{
			headers: {
				Authorization: `${auth.getToken()}`,
			},
		}
	);
	if (validateFileResponse.status !== 200) {
		return null;
	}
	return validateFileResponse.data;
};

const parseIngestionFile = async (fileName, fileData, type = "PROVIDER") => {
	const getPresignedUrlResponse = await axios.get(
		`${API_ENDPOINT}/getPresignedUrl`,
		{
			headers: {
				Authorization: `${auth.getToken()}`,
			},
			params: {
				objectName: fileName,
			},
		}
	);
	if (getPresignedUrlResponse.status !== 200) {
		return null;
	}
	const presignedUrl = getPresignedUrlResponse.data.url;

	const putObjectResponse = await axios.put(presignedUrl, fileData, {
		headers: {
			"Content-Type": "application/octet-stream",
		},
	});
	if (putObjectResponse.status !== 200) {
		return null;
	}

	const parseFileResponse = await axios.post(
		`${API_ENDPOINT}/parseIngestionFile`,
		{
			type: type,
			fileName: fileName,
		},
		{
			headers: {
				Authorization: `${await auth.getToken()}`,
			},
		}
	);
	if (parseFileResponse.status !== 200) {
		return null;
	}
	return parseFileResponse.data;
};

const submitFile = async (fileName, fileData, type = "PROVIDER") => {
	const getPresignedUrlResponse = await axios.get(
		`${API_ENDPOINT}/getPresignedIngestionUrl`,
		{
			headers: {
				Authorization: `${await auth.getToken()}`,
			},
			params: {
				objectName: fileName,
				type: type,
			},
		}
	);
	if (getPresignedUrlResponse.status !== 200) {
		return null;
	}
	const presignedUrl = getPresignedUrlResponse.data.url;

	const putObjectResponse = await axios.put(presignedUrl, fileData, {
		headers: {
			"Content-Type": "application/octet-stream",
		},
	});
	if (putObjectResponse.status !== 200) {
		return null;
	}
	return putObjectResponse.data;
};

const getIngestionLogs = async (type, dateFrom, dateTo) => {
	const logIdentifier = `${type}-ingestion-lambda`;
	const response = await axios.get(`${API_ENDPOINT}/getLogs`, {
		headers: {
			Authorization: `${auth.getToken()}`,
		},
		params: {
			logIdentifier,
			dateFrom:
				typeof dateFrom === "string" ? dateFrom : dateFrom.toISOString(),
			dateTo: typeof dateTo === "string" ? dateTo : dateTo.toISOString(),
		},
	});
	if (response.status !== 200) {
		return null;
	}
	return response.data;
};

const createNetwork = async (network) => {
	const response = await axios.post(
		`${API_ENDPOINT}/createNetwork`,
		{
			network,
		},
		{
			headers: {
				Authorization: `${auth.getToken()}`,
			},
		}
	);
	if (response.status !== 200) {
		return null;
	}
	return response.data.authToken;
};

const updateNetwork = async (network) => {
	const response = await axios.put(
		`${API_ENDPOINT}/updateNetwork`,
		{
			network,
		},
		{
			headers: {
				Authorization: `${auth.getToken()}`,
			},
		}
	);
	if (response.status !== 200) {
		return null;
	}
	return response.data.authToken;
};

const doesSchedulingUrlHit = async (url) => {
	const response = await axios.get(`${API_ENDPOINT}/checkPatientServiceUrl`, {
		headers: {
			Authorization: `${auth.getToken()}`,
		},
		params: {
			url,
		},
	});

	return response;
};

const getDoctorsByNpi = async (npi) => {
	const response = await axios.get(`${API_ENDPOINT}/getDoctorsByNpi`, {
		headers: {
			Authorization: `${auth.getToken()}`,
		},
		params: {
			npi,
		},
	});
	if (response.status !== 200) {
		return null;
	}
	return response.data;
};

const getDoctorsByTin = async (tin, lastEvaluatedKey = null) => {
	const response = await axios.get(`${API_ENDPOINT}/getDoctorsByTin`, {
		headers: {
			Authorization: `${auth.getToken()}`,
		},
		params: {
			tin,
			lastEvaluatedKey,
		},
	});
	if (response.status !== 200) {
		return null;
	}
	// FIXME: make return of BE consistent with getDoctorsByNpi
	return response.data;
};

const loopGetDoctorsByTin = async (tin) => {
	const items = [];
	const dataResponse = await getDoctorsByTin(tin);
	let lastEvaluatedKey = dataResponse.LastEvaluatedKey;

	items.push(...dataResponse.items);

	while (lastEvaluatedKey) {
		const dataResponse = await getDoctorsByTin(tin, lastEvaluatedKey);
		lastEvaluatedKey = dataResponse.LastEvaluatedKey;

		items.push(...dataResponse.items);
	}

	return items;
};

const getFacilitiesByTin = async (tin) => {
	const response = await axios.get(`${API_ENDPOINT}/getFacilitiesByTin`, {
		headers: {
			Authorization: `${auth.getToken()}`,
		},
		params: {
			tin,
		},
	});
	if (response.status !== 200) {
		return null;
	}
	return response.data;
};

const getAllNetworks = async () => {
	const response = await axios.get(`${API_ENDPOINT}/getAllNetworks`, {
		headers: {
			Authorization: `${auth.getToken()}`,
		},
		params: {},
	});
	if (response.status !== 200) {
		return null;
	}
	return response.data;
};

const peliasHealthCheck = async () => {
	try {
		const response = await axios.get(
			`${process.env.PELIAS_MAP_SERVER}/v1/search?text=624 Quaker Ln Ste 201D High Point NC 27262`,
			{
				headers: {
					"Cache-Control": "no-cache",
					Accept: "*/*",
					"Accept-Encoding": "gzip, deflate, br",
					// Connection:'keep-alive'
				},
				params: {},
			}
		);
		if (response.status !== 200) {
			return null;
		}
		return response.data;
	} catch (e) {
		return null;
	}
};

const updateCarepointRecord = async (data) => {
	try {
		const response = await axios.put(
			`${API_ENDPOINT}/updateCarepointRecord`,
			data,
			{
				headers: {
					Authorization: `${auth.getToken()}`,
				},
				params: {},
			}
		);

		return response.data;
	} catch (error) {
		return error;
	}
};

const updateFacilityRecord = async (data) => {
	try {
		const response = await axios.put(
			`${API_ENDPOINT}/updateFacilityRecord`,
			data,
			{
				headers: {
					Authorization: `${auth.getToken()}`,
				},
				params: {},
			}
		);

		return response.data;
	} catch (error) {
		return error;
	}
};

const fargateDistributionTask = async (
	email,
	tableDist = undefined,
	scriptName = undefined,
	dateFrom,
	dateTo,
	allData
) => {
	console.log("WHAT THE FUCK");
	try {
		const response = await axios.post(
			`${API_ENDPOINT}/testFargateDistributionTask`,
			{
				email,
				tableDist,
				dateFrom: dateFrom
					? typeof dateFrom === "string"
						? dateFrom
						: dateFrom.toISOString()
					: undefined,
				dateTo: dateTo
					? typeof dateTo === "string"
						? dateTo
						: dateTo.toISOString()
					: undefined,
				allData: allData,
				scriptName: scriptName,
			},
			{
				headers: { Authorization: `${auth.getToken()}` },
			}
		);
		if (response.status !== 200) {
			return null;
		}
		return response.data.authToken;
	} catch (e) {
		console.log(e);
		return null;
	}
};

const getIngestionModel = async (type) => {
	const response = await axios.get(`${API_ENDPOINT}/getIngestionModel`, {
		headers: {
			Authorization: `${auth.getToken()}`,
		},
		params: {
			type,
		},
	});
	if (response.status !== 200) {
		return null;
	}
	return response.data;
};

const downloadS3Url = async (url) => {
	const response = await axios.get(url);
	if (response.status !== 200) {
		return null;
	}
	return response.data;
};

// TODO: this conforms to the server
const generateIngestionErrorArray = (chunk) => {
	let errorArray = [];
	for (let item of chunk) {
		errorArray.push({
			item: item,
			status: "COMPLETE NETWORK FAILURE TRY AGAIN",
		});
	}
	return errorArray;
};

const ingestRecords = async (records, type) => {
	try {
		const response = await axios.post(
			`${API_ENDPOINT}/ingestRecords`,
			{
				records,
				type,
			},
			{
				headers: { Authorization: `${auth.getToken()}` },
			}
		);
		if (response.status !== 200) {
			return generateIngestionErrorArray(records);
		}
		return response.data;
	} catch (e) {
		return generateIngestionErrorArray(records);
	}
};

const ingestRecordsBatch = async (records, type) => {
	let fullResults = [];
	const chunks = jsUtils.chunkArrayInGroups(records, 100);
	fullResults = await jsUtils.batchOperation(
		async (chunk) => {
			try {
				return await ingestRecords(chunk, type);
			} catch (e) {
				console.log(e);
				return generateIngestionErrorArray(chunk);
			}
		},
		chunks,
		10
	);
	let resultSet = [];
	for (let arrayResult of fullResults) {
		resultSet.push(...arrayResult);
	}
	return resultSet;
};

const getFlaggedRecords = async (
	type,
	lastEvalKey = null,
	flagged = "true"
) => {
	const response = await axios.get(`${API_ENDPOINT}/getFlaggedRecords`, {
		headers: {
			Authorization: `${auth.getToken()}`,
		},
		params: {
			type,
			lastEvalKey,
			flagged,
		},
	});
	if (response.status !== 200) {
		return null;
	}
	return response.data;
};

export default {
	login,
	logout,
	sendAccessCode,
	verifyAccount,
	validateFile,
	parseIngestionFile,
	submitFile,
	getIngestionLogs,
	getDoctorsByNpi,
	getDoctorsByTin,
	loopGetDoctorsByTin,
	getFacilitiesByTin,
	createNetwork,
	getAllNetworks,
	peliasHealthCheck,
	updateCarepointRecord,
	updateFacilityRecord,
	updateNetwork,
	fargateDistributionTask,
	getIngestionModel,
	downloadS3Url,
	ingestRecords,
	ingestRecordsBatch,
	getFlaggedRecords,
	doesSchedulingUrlHit,
};
