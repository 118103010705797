import React from "react";

export default class FormCheckbox extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { label, onChange, isDisabled } = this.props;
		return (
			<div className="form-checkbox">
				<label className="checkbox">
					<input
						type="checkbox"
						disabled={isDisabled}
						checked={this.props.value}
						onChange={() => {
							onChange(!this.props.value);
						}}
					/>
					{label}
				</label>
			</div>
		);
	}
}
