/** @jsx jsx */
// eslint-disable-next-line
import React, { useEffect, useState } from "react";
import chunk from "lodash.chunk";

import { TablePager } from "./TablePager";
import { jsx, css } from "@emotion/react";
import { NoData } from "./NoData";

const container = css`
	white-space: nowrap;
	font-size: 12px;
	overflow: auto;
`;

const TableHeader = ({ header }) => (
	<thead>
		<tr>
			{header.map((col) => (
				<th key={`h-${col}`} className="has-white-text has-background-grey">
					{col}
				</th>
			))}
		</tr>
	</thead>
);

const TableRow = ({ row, index: rowIndex }) => (
	<tr>
		{row.map((col, colIndex) => (
			<td key={`c-${rowIndex}-${colIndex}`}>{col}</td>
		))}
	</tr>
);

const TableBody = ({ rows }) => (
	<tbody>
		{rows.map((row, index) => (
			<TableRow key={`r-${index}`} row={row} index={index} />
		))}
	</tbody>
);

/*
data : {
	headers : String[],
	rows: Array<any[]>
}
*/

export const Table = ({ data, pageSize: _pageSize = 0 }) => {
	const [chunks, setChunks] = useState([data.rows]);
	const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = useState(_pageSize);

	useEffect(() => {
		if (pageSize === 0) {
			setChunks([data.rows]);
			return;
		}

		setChunks(chunk(data.rows, pageSize));
	}, [data, pageSize]);

	const hasData = chunks[page];

	return (
		<>
			<TablePager
				pages={Math.max(chunks.length - 1, 0)}
				onPageChange={setPage}
				pageSize={pageSize}
				onPageSizeChange={setPageSize}
				totalCount={data.rows.length}
			/>
			<div css={container}>
				<table className="table is-bordered is-fullwidth">
					<TableHeader header={data.header} />
					{hasData && <TableBody rows={chunks[page]} />}
				</table>
				{!hasData && <NoData />}
			</div>
		</>
	);
};
