/**
 * @param {*} ms
 * @returns
 */
const sleep = (ms) => {
	return new Promise((resolve) => setTimeout(resolve, ms));
};

/**
 * @param {*} arr
 * @param {*} size
 * @returns
 */
const chunkArrayInGroups = (arr, size) => {
	var myArray = [];
	for (var i = 0; i < arr.length; i += size) {
		myArray.push(arr.slice(i, i + size));
	}
	return myArray;
};

/**
 * @param {*} batchMethod
 * @param {*} items
 * @param {*} batchSize
 */
const batchOperation = async (
	batchMethod,
	items,
	batchSize = 50,
	sleepMs = null,
	logProgress = false
) => {
	let batch = [];
	let results = [];
	for (let i = 0; i < items.length; i++) {
		batch.push(batchMethod(items[i]));
		if (batch.length === batchSize || i === items.length - 1) {
			let batchResults = await Promise.all(batch);
			results.push(...batchResults);
			batch = [];

			if (logProgress === true) {
				console.log(items.length, i);
			}
			if (sleepMs != null) {
				await sleep(sleepMs);
			}
		}
	}
	return results;
};

export default {
	sleep,
	chunkArrayInGroups,
	batchOperation,
};
