import React, { useEffect, useState } from "react";
import { Table } from "../../components/Table";
import { NetworksToolbar } from "./NetworksToolbar";

export const NetworksTable = ({ networks, onNew }) => {
	const [filteredNetworks, setFilteredNetworks] = useState(networks);
	const [searchTerm, setSearchTerm] = useState("");
	const [showAll, setShowAll] = useState(false);

	useEffect(() => {
		const onlyStrings = (cel) => typeof cel === "string";

		const filterByTerm = (row) =>
			row &&
			row
				.filter(onlyStrings)
				.findIndex((cel) => cel && cel.includes(searchTerm)) !== -1;
		const filterServiceRecords = (row) =>
			row &&
			row
				.filter(onlyStrings)
				.findIndex((cel) => cel && cel.includes("serviceRecord#")) === -1;

		const filteredByShowAll = {
			header: networks.header,
			rows: showAll
				? networks.rows
				: networks.rows.filter(filterServiceRecords),
		};

		if (!searchTerm) {
			setFilteredNetworks(filteredByShowAll);
			return;
		}

		const result = {
			header: networks.header,
			rows: filteredByShowAll.rows.filter(filterByTerm),
		};

		setFilteredNetworks(result);
	}, [searchTerm, showAll, networks]);

	const handleSearch = (term) => {
		setSearchTerm(term);
	};

	const handleShowAll = (checked) => {
		setShowAll(checked);
	};

	if (!filteredNetworks) return;

	return (
		<>
			<NetworksToolbar
				onNew={onNew}
				onSearch={handleSearch}
				onShowAllChanged={handleShowAll}
				checked={showAll}
			/>
			<Table data={filteredNetworks} pageSize={25} />
		</>
	);
};
