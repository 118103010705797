import React, { useEffect, useState } from "react";
import { Table } from "../../../components/Table";
import { FacilityEditor } from "./FacilityEditor";
import { voidFn } from "../../../utils/voidFn";

const Header = [
	"Action",
	"Network",
	"TIN",
	"NPI",
	"Legal Name",
	"Practice Name",
	"Address1",
	"Address2",
	"City",
	"State",
	"ZipCode",
	"County",
	"Specialty Code",
	"Specialty Type",
	"Source Specialty",
	"Second Source Specialty",
	"Publish",
	"Start Date",
	"End Date",
	"Termination Date",
	"Fax",
	"Billing Address1",
	"Billing Address2",
	"Billing City",
	"Billing State",
	"Billing Zip Code",
	"Billing Phone Number",
	"Billing Fax",
	"Billing Email",
	"Location ID",
	"Base Location ID",
	"Lat",
	"Long",
];

const InitialFacilitiesData = {
	header: Header,
	rows: [],
};

export const FacilitiesTable = ({ facilities, onUpdate = voidFn }) => {
	const [data, setData] = useState(InitialFacilitiesData);

	useEffect(() => {
		const rows = facilities.map((r) => [
			<FacilityEditor facility={r} onUpdate={onUpdate} />,
			r.network,
			r.tin,
			r.npi,
			r.legalName,
			r.practiceName,
			r.address1,
			r.address2,
			r.city,
			r.state,
			r.zipCode,
			r.county,
			r.specialtyCode,
			r.specialtyType,
			r.sourceSpecialty,
			r.secondSourceSpecialty,
			r.publish,
			r.startDate,
			r.endDate,
			r.terminationDate,
			r.fax,
			r.billingAddress1,
			r.billingAddress2,
			r.billingCity,
			r.billingState,
			r.billingZipCode,
			r.billingPhoneNumber,
			r.billingFax,
			r.billingEmail,
			r.locationID,
			r.baseLocationID,
			r.lat,
			r.long,
		]);

		setData({
			header: Header,
			rows,
		});
	}, [facilities, onUpdate]);

	return <Table data={data} pageSize={25} />;
};
