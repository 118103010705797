import React, { useState } from "react";
import services from "../../services";
import logo from "../../../img/centivo-logo.svg";
import { BigButton } from "../../components/BigButton";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { tryRunAsyncFactory } from "../../utils/tryRunAsyncFactory";
import { toast } from "react-toastify";

const InitialFormState = {
	username: "",
	password: "",
	accessCode: "",
};

export const FirstTimeLoginForm = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [formData, setFormData] = useState(InitialFormState);

	const tryRunAsync = tryRunAsyncFactory(setIsLoading);

	const canSubmit =
		formData.username && formData.password && formData.accessCode;

	const handleChange =
		(fieldName) =>
		({ target }) => {
			setFormData({
				...formData,
				[fieldName]: target.value,
			});
		};

	const handleSubmit = async () =>
		await tryRunAsync(async () => {
			const { accessCode, password, username } = formData;
			await services.api.verifyAccount(username, accessCode, password);
			toast.success("Account successfully verified");
			setFormData(InitialFormState);
		});

	const handleSendAccessCode = async () =>
		await tryRunAsync(async () => {
			await services.api.sendAccessCode(formData.username);
			toast.success("Access Code sent. Please check your emails");
		});

	const handleKeyPress = async (event) => {
		if (canSubmit && event.code === "Enter") {
			await handleSubmit();
		}
	};

	return (
		<>
			<LoadingSpinner isLoading={isLoading} />
			<div className="box login-box-border">
				<div className="box">
					<img src={logo} alt="Centivo Logo" />
				</div>
				<div className="title has-text-grey is-5">
					Please enter your email access code and new password.
				</div>

				<form onKeyPress={handleKeyPress}>
					<div className="field">
						<div className="control">
							<input
								className="input is-large is-primary2-color-underline"
								type="email"
								placeholder="Email"
								autoComplete="email"
								value={formData.username}
								onChange={handleChange("username")}
							/>
						</div>
					</div>
					<div className="field">
						<div className="control">
							<span className="is-flex is-flex-direction-row is-align-items-center">
								<input
									className="input is-large is-primary2-color-underline is-flex-grow-4"
									type="number"
									placeholder="Access Code"
									autoComplete="off"
									value={formData.accessCode}
									onChange={handleChange("accessCode")}
								/>
								<button
									type="button"
									className="button is-large is-flex-grow-1 is-primary"
									onClick={handleSendAccessCode}
									disabled={!formData.username}
								>
									Send Code
								</button>
							</span>
						</div>
					</div>
					<div className="field">
						<div className="control">
							<input
								className="input is-large is-primary2-color-underline"
								type="password"
								placeholder="New Password"
								autoComplete="new-password"
								value={formData.password}
								onChange={handleChange("password")}
							/>
						</div>
					</div>
				</form>
				<BigButton onClick={handleSubmit} disabled={!canSubmit}>
					Submit
				</BigButton>
			</div>
		</>
	);
};
