import React, { useEffect, useState } from "react";
import services from "../../../services";
import { tryRunAsyncFactory } from "../../../utils/tryRunAsyncFactory";
import { ProviderSearchbar, SearchType } from "./ProviderSearchbar";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { useMounted } from "../../../hooks/useMounted";
import { ProvidersTable } from "./ProvidersTable";

const FetchProvidersFunctions = {
	[SearchType.NPI]: services.api.getDoctorsByNpi,
	[SearchType.TIN]: services.api.loopGetDoctorsByTin,
};

export const Providers = () => {
	const [providers, setProviders] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [searchState, setSearchState] = useState(null);
	const isMounted = useMounted();

	const tryRunAsync = tryRunAsyncFactory(setIsLoading);

	const fetchProviders = () =>
		tryRunAsync(async () => {
			const { term, type } = searchState;
			const fetchProvider = FetchProvidersFunctions[type];

			if (!fetchProvider) {
				console.error("Unknown type", type);
				return;
			}
			const providers = await fetchProvider(term);

			if (isMounted) {
				setProviders(providers);
			}
		});

	useEffect(() => {
		searchState && fetchProviders();
		// eslint-disable-next-line
	}, [searchState]);

	const handleSearch = (search) => {
		setSearchState(search);
	};

	const handleUpdate = () => {
		fetchProviders();
	};

	return (
		<>
			<LoadingSpinner isLoading={isLoading} />
			<ProviderSearchbar onSearch={handleSearch} />
			<div className="p-4">
				{providers && (
					<ProvidersTable providers={providers} onUpdate={handleUpdate} />
				)}
			</div>
		</>
	);
};
