import React from "react";
import { voidFn } from "../../utils/voidFn";
import { BigButton } from "../../components/BigButton";

export const TileActions = ({
	title,
	actions,
	disabled = false,
	onAction = voidFn,
}) => (
	<div>
		<h2 className="subtitle is-3 mt-3 mb-1">{title}</h2>
		<div className="tile is-ancestor">
			{actions.map(({ text, type }, index) => (
				<BigButton
					key={`type-${index}`}
					onClick={() => onAction(type)}
					disabled={disabled}
				>
					{text}
				</BigButton>
			))}
		</div>
	</div>
);
