import React, { useEffect, useState } from "react";
import { Searchbar } from "../../../components/Searchbar";
import { useMounted } from "../../../hooks/useMounted";
import { tryRunAsyncFactory } from "../../../utils/tryRunAsyncFactory";
import service from "../../../services";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { FacilitiesTable } from "./FaciltitiesTable";

export const Facilities = () => {
	const [facilities, setFacilities] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [searchState, setSearchState] = useState(null);
	const isMounted = useMounted();

	const tryRunAsync = tryRunAsyncFactory(setIsLoading);

	const fetchFacilities = () =>
		tryRunAsync(async () => {
			const facilities = await service.api.getFacilitiesByTin(searchState);
			isMounted && setFacilities(facilities);
		});

	useEffect(() => {
		searchState && fetchFacilities();
		// eslint-disable-next-line
	}, [searchState]);

	const handleSearch = (search) => {
		setSearchState(search);
	};

	const handleUpdate = () => {
		fetchFacilities();
	};

	return (
		<>
			<LoadingSpinner isLoading={isLoading} />
			<div className="container">
				<Searchbar
					onSearch={handleSearch}
					placeholder="Enter Facilities TIN to search"
				/>
			</div>
			<div className="p-4">
				{facilities && (
					<FacilitiesTable facilities={facilities} onUpdate={handleUpdate} />
				)}
			</div>
		</>
	);
};
