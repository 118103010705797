import React from "react";

export default class FormInput extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { value, isDisabled, label, placeholder, onChange } = this.props;
		return (
			<div className="form-input">
				<label className="label">{label}</label>
				<div className="control">
					<input
						className="input"
						type="text"
						value={value}
						disabled={isDisabled}
						placeholder={placeholder}
						onChange={(text) => onChange(text.target.value)}
					/>
				</div>
			</div>
		);
	}
}
