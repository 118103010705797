import ReactJson from "react-json-view";
import React from "react";

export const JsonTree = ({ json, title }) => (
	<div className="is-margin-top">
		<h2 className="subtitle is-3">{title}:</h2>
		<div style={{ backgroundColor: "white", width: "100%", height: "100%" }}>
			<ReactJson src={json} theme="summerfruit:inverted" collapsed={1} />
		</div>
	</div>
);
