/** @jsx jsx */
import { jsx } from "@emotion/react";
import { useEffect, useRef, useState } from "react";
import { InputFormField } from "./InputFormField";
import { InputFormDateField } from "./InputFormDateField";
import { TagListFormField } from "./TagListFormField";
import api from "../../../services/api";

const InitialFormData = {
	providerDomainNetwork: {
		value: "",
	},
	networkCode: {
		value: "",
	},
	networkName: {
		value: "",
	},
	planNetworkCodes: {
		value: "",
	},
	pricingStrategy: {
		value: "",
	},
	professionalPercent: {
		value: "",
	},
	facilityPercent: {
		value: "",
	},
	contractOwner: {
		value: "",
	},
	networkAffiliationDate: {
		value: "",
	},
	contractName: {
		value: "",
	},
	patientSchedulingURL: {
		value: "",
	},
	patientPortalURL: {
		value: "",
	},
	isClaimEditsAllowed: {
		value: false,
	},
};

export const NetworkForm = ({
	onChange,
	network = null,
	existingNetworks = null,
}) => {
	const [formData, setFormData] = useState(InitialFormData);
	const providerDomainNetworks = useRef(new Set());

	useEffect(() => {
		if (!providerDomainNetworks.current) {
			return;
		}
		providerDomainNetworks.current.clear();
		existingNetworks.forEach(({ providerDomainNetwork }) => {
			providerDomainNetworks.current.add(providerDomainNetwork);
		});
	}, [existingNetworks]);

	useEffect(() => {
		if (!network) return;
		setFormData(dehydrateFormData(network));
	}, [network]);

	useEffect(() => {
		onChange(hydrateFormData());
	}, [formData]);

	const handleFormChange = (fieldName) => (value) => {
		const meta = validateFieldData({ fieldName, value });
		const updatedFormData = {
			...formData,
			[fieldName]: {
				meta,
				value,
			},
		};
		setFormData(updatedFormData);
	};

	const handleUrlValidation = async (fieldName, urlValue) => {
		if (!urlValue) {
			console.log("urlValue is empty");
			return;
		}
		if (!validateURLPattern(urlValue)) {
			setUrlFieldError(fieldName, urlValue, "patternMismatch");
			return;
		}
		try {
			await api.doesSchedulingUrlHit(urlValue);
		} catch (error) {
			console.log("error in handleUrlValidation:", error);
			setUrlFieldError(fieldName, urlValue, "linkUnreachable");
		}
	};

	const validateURLPattern = (value) => {
		// make sure patient field url's must always have https://
		const urlRegex = /^https:\/\//;
		return urlRegex.test(value);
	};

	const setUrlFieldError = (fieldName, urlValue, type) => {
		let meta = {};
		switch (type) {
			case "patternMismatch":
				meta = { error: "Link must start with https://" };
				break;
			case "linkUnreachable":
				if (fieldName === "patientSchedulingURL") {
					meta = { error: "Invalid patient scheduling URL" };
				}
				if (fieldName === "patientPortalURL") {
					meta = { error: "Invalid patient portal URL" };
				}
				break;
			default:
				meta = {};
				break;
		}
		const updatedFormData = {
			...formData,
			[fieldName]: {
				meta,
				value: urlValue,
			},
		};
		setFormData(updatedFormData);
	};

	const handleCheckBoxChange = () => {
		setFormData({
			...formData,
			isClaimEditsAllowed: {
				value: !formData.isClaimEditsAllowed.value,
			},
		});
	};

	const validateFieldData = ({ fieldName, value }) => {
		let meta = {};
		if (
			fieldName === "providerDomainNetwork" &&
			providerDomainNetworks.current.has(value.trim())
		) {
			meta = { error: "Item already exists" };
		}
		return meta;
	};

	const dehydrateFormData = (hydrated) => {
		let data = {};
		Object.keys(hydrated).forEach((fieldName) => {
			data[fieldName] = {
				value: hydrated[fieldName],
			};
		});
		return data;
	};

	const hydrateFormData = () => {
		let hydrated = {};
		Object.keys(formData).forEach((fieldName) => {
			console.log("FIELD NAME:", fieldName, formData[fieldName].value);
			hydrated[fieldName] = formData[fieldName].value;
		});
		return hydrated;
	};

	const formatPercent = (value) => {
		if (!value) return "";
		if (value.endsWith(".")) {
			return value;
		}
		if (value.toString().length === 1) {
			if (value.toString().toLowerCase().startsWith("n")) {
				return value;
			}
		}
		if (value.toString().length === 2) {
			if (value.toString().toLowerCase().charAt(1) === "/") {
				return value;
			}
		}
		if (value.toString().length === 3) {
			if (value.toString().toLowerCase().endsWith("a")) {
				return value;
			}
		}

		let v = parseFloat(value);
		if (Number.isNaN(v)) {
			v = 0;
		}
		return Math.min(Math.max(0.0, v), 1000.0);
	};

	const isUpdateMode = network !== null;

	return (
		<form>
			<div className="panel-block">
				<InputFormField
					name="providerDomainNetwork"
					formData={formData}
					onChange={handleFormChange}
					readonly={isUpdateMode}
				/>
			</div>
			<div className="panel-block">
				<TagListFormField
					name="networkCode"
					formData={formData}
					customLabel="JV Network Codes (comma separated)"
					onChange={handleFormChange}
				/>
			</div>
			<div className="panel-block">
				<InputFormField
					name="networkName"
					formData={formData}
					onChange={handleFormChange}
				/>
			</div>
			<div className="panel-block">
				<InputFormField
					name="planNetworkCodes"
					customLabel="Network Code"
					formData={formData}
					onChange={handleFormChange}
				/>
			</div>
			<div className="panel-block">
				<InputFormField
					name="pricingStrategy"
					formData={formData}
					onChange={handleFormChange}
				/>
			</div>
			<div className="panel-block">
				<InputFormField
					name="professionalPercent"
					formData={formData}
					onChange={handleFormChange}
					formatFunction={formatPercent}
				/>
			</div>
			<div className="panel-block">
				<InputFormField
					name="facilityPercent"
					formData={formData}
					onChange={handleFormChange}
					formatFunction={formatPercent}
				/>
			</div>
			<div className="panel-block">
				<InputFormField
					name="contractOwner"
					formData={formData}
					onChange={handleFormChange}
				/>
			</div>
			<div className="panel-block">
				<InputFormField
					customLabel="Network Affiliation Date (YYYY-MM-DD)"
					name="networkAffiliationDate"
					formData={formData}
					onChange={handleFormChange}
				/>
			</div>
			{/* <div className="panel-block">
				<InputFormDateField
					name="networkAffiliationDate"
					formData={formData}
					onChange={handleFormChange}
				/>
			</div> */}
			<div className="panel-block">
				<InputFormField
					name="contractName"
					formData={formData}
					onChange={handleFormChange}
				/>
			</div>
			<div className="panel-block">
				<InputFormField
					name="patientSchedulingURL"
					formData={formData}
					onChange={handleFormChange}
					onBlur={handleUrlValidation}
				/>
			</div>
			<div className="panel-block">
				<InputFormField
					name="patientPortalURL"
					formData={formData}
					onChange={handleFormChange}
					onBlur={handleUrlValidation}
				/>
			</div>
			{formData.isClaimEditsAllowed !== undefined && (
				<div className="panel-block is-flex is-justify-content-center">
					<label className="checkbox">
						<input
							type="checkbox"
							checked={formData.isClaimEditsAllowed.value}
							onChange={handleCheckBoxChange}
						/>
						<span className="ml-1 is-size-5">Claim Edits Allowed</span>
					</label>
				</div>
			)}
		</form>
	);
};
