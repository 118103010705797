import { Searchbar } from "../../components/Searchbar";
import React from "react";

export const NetworksToolbar = ({
	onSearch,
	onNew,
	onShowAllChanged,
	showAll,
}) => {
	const handleShowAll = ({ target }) => {
		onShowAllChanged(target.checked);
	};

	return (
		<div className="is-flex is-flex-direction-row is-align-items-center">
			<div className="is-flex-grow-1">
				<label className="checkbox">
					<input
						className="mr-2"
						type="checkbox"
						onClick={handleShowAll}
						value={showAll}
					/>
					Show System Networks
				</label>
			</div>
			<div className="is-flex-grow-5">
				<Searchbar onSearch={onSearch} />
			</div>
			<div className="ml-1">
				<button className="button is-primary2-button" onClick={onNew}>
					<span className="icon">
						<i className="fas fa-network-wired" />
					</span>
					<span>New</span>
				</button>
			</div>
		</div>
	);
};
