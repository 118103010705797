import React from "react";

export default class FormDropdown extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	renderOptions = () => {
		let options = [];
		this.props.options.forEach((item) => {
			options.push(<option>{item}</option>);
		});
		return options;
	};

	render() {
		const { label, value, onChange } = this.props;
		return (
			<div className="form-dropdown">
				<label className="label">{label}</label>
				<div className="control">
					<div className="select" value={value}>
						<select onChange={(text) => onChange(text.target.value)}>
							{this.renderOptions()}
						</select>
					</div>
				</div>
			</div>
		);
	}
}
