/** @jsx jsx */
import { useState } from "react";
import { css, jsx } from "@emotion/react";
import Dropzone from "react-dropzone";
import { voidFn } from "../../utils/voidFn";
import { hover } from "../../styles/hover";

const closeButton = css`
	position: absolute;
	top: 0;
	right: 0;
	height: 16px;
	width: 16px;
`;

const InitialState = {
	name: "",
	data: null,
};

export const DragDrop = ({ onFile = voidFn, onReset = voidFn }) => {
	const [selectedFile, setSelectedFile] = useState(InitialState);

	const handleSelectedFile = (acceptedFiles) => {
		const file = acceptedFiles[0];
		const reader = new FileReader();
		reader.readAsArrayBuffer(file);
		reader.addEventListener("load", (e) => {
			const fileData = {
				name: file.name.toString(),
				data: e.target.result,
			};
			setSelectedFile(fileData);
			onFile(fileData);
		});
	};

	const handleReset = () => {
		setSelectedFile(InitialState);
		onReset();
	};

	return (
		<div className="is-margin-top is-flex is-relative">
			<article
				className="tile is-child box is-bordered has-white-text"
				css={hover}
				style={{ backgroundColor: "#259c90" }}
			>
				<Dropzone onDrop={handleSelectedFile}>
					{({ getRootProps, getInputProps }) => (
						<section>
							<div {...getRootProps()}>
								<input {...getInputProps()} />
								{selectedFile.data && <p>{selectedFile.name}</p>}
								{!selectedFile.data && (
									<p
										className="is-text is-centered is-align-center has-text-centered-desktop"
										style={{
											minHeight: "160px",
											backgroundColor: "#259c90",
										}}
									>
										Drag 'n' drop some files here, or click to select files
									</p>
								)}
							</div>
						</section>
					)}
				</Dropzone>
			</article>
			{selectedFile.data && (
				<div css={closeButton} className="is-clickable" onClick={handleReset}>
					<i className="fas fa-times" />
				</div>
			)}
		</div>
	);
};
