import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default class FormDatePicker extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<div className="form-input">
				<label className="label">{this.props.label}</label>
				<div className="control">
					<DatePicker
						selected={this.props.value}
						onChange={(date) => this.props.onChange(date)}
					/>
				</div>
			</div>
		);
	}
}
