import { identityFn } from "./identityFn";

/**
 * Returns an alternative value in case of null/undefined
 * @param v The possibly null value
 * @param transformFn Transformation function for _v_
 * @param inCaseOfNull The return value in case of null/undefined
 * @return {string|*} inCaseOfNull or return value of transformFn
 */
export const nullable = (v, transformFn = identityFn, inCaseOfNull = "") =>
	v === null || v === undefined ? inCaseOfNull : transformFn(v);
