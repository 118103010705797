import React, { useState } from "react";
import { Searchbar } from "../../../components/Searchbar";

export const SearchType = {
	NPI: "npi",
	TIN: "tin",
};

export const ProviderSearchbar = ({ onSearch }) => {
	const [type, setType] = useState(SearchType.NPI);

	const handleTypeChange = ({ target }) => {
		setType(target.value);
	};

	const handleSearch = (term) => {
		onSearch({
			term,
			type,
		});
	};

	const isChecked = (t) => t === type;

	return (
		<div className="container">
			<div className="is-flex is-flex-direction-row is-justify-content-flex-center is-align-items-center">
				<Searchbar
					onSearch={handleSearch}
					placeholder={`Enter Providers ${type.toUpperCase()} to search`}
				/>
				<div className="ml-4 is-flex is-flex-direction-row is-align-items-center">
					<label className="radio is-flex is-flex-direction-row is-align-items-center">
						<input
							type="radio"
							name="type"
							className="mr-1"
							value={SearchType.NPI}
							checked={isChecked(SearchType.NPI)}
							onChange={handleTypeChange}
						/>
						NPI
					</label>
					<label className="radio is-flex is-flex-direction-row is-align-items-center">
						<input
							type="radio"
							name="type"
							className="mr-1"
							value={SearchType.TIN}
							checked={isChecked(SearchType.TIN)}
							onChange={handleTypeChange}
						/>
						TIN
					</label>
				</div>
			</div>
		</div>
	);
};
