import React, { createRef, useEffect, useMemo } from "react";
import { NetworkForm } from "./NetworkForm";
import { Modal } from "../../../components/Modal";

export const NetworkEditor = ({
	onSubmit,
	onCancel,
	network,
	existingNetworks,
	open,
	isLoading,
}) => {
	const data = createRef();

	const filteredExistingNetwork = useMemo(
		() =>
			(existingNetworks ?? []).filter(
				(n) => !(n.id ?? "").includes("serviceRecord#")
			),
		[existingNetworks]
	);

	useEffect(() => {
		data.current = network;
	}, [network, data]);

	const isUpdateMode = network !== null;

	const handleModalAction = (action) => {
		switch (action) {
			case "confirm":
				onSubmit(data.current);
				break;
			case "cancel":
			default:
				onCancel();
		}
	};

	const handleOnChange = (formData) => {
		data.current = formData;
	};

	return (
		open && (
			<Modal
				title={isUpdateMode ? "Edit Network" : "New Network"}
				onClose={() => handleModalAction("cancel")}
				isVisible={open}
				onAction={handleModalAction}
				actionsDisabled={isLoading}
			>
				<NetworkForm
					onChange={handleOnChange}
					network={network}
					existingNetworks={filteredExistingNetwork}
				/>
			</Modal>
		)
	);
};
