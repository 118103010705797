import React, { useEffect, useMemo, useState } from "react";
import logo from "../../../img/centivo-logo.svg";
import { tryRunAsyncFactory } from "../../utils/tryRunAsyncFactory";
import services from "../../services";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { BigButton } from "../../components/BigButton";
import { LoadingSpinner } from "../../components/LoadingSpinner";

const InitialCredentialsState = {
	username: "",
	password: "",
};

export const LoginForm = () => {
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const [credentials, setCredentials] = useState(InitialCredentialsState);
	const [loggedIn, setLoggedIn] = useState(false);

	const canLogin = credentials.username && credentials.password;

	const tryRunAsync = useMemo(
		() =>
			tryRunAsyncFactory(setIsLoading, () => {
				toast.error("Login failed");
			}),
		[]
	);

	useEffect(() => {
		loggedIn && history.replace("/");
	}, [loggedIn, history]);

	const handleChange =
		(fieldName) =>
		({ target }) => {
			setCredentials({
				...credentials,
				[fieldName]: target.value,
			});
		};

	const handleLogin = async () =>
		await tryRunAsync(async () => {
			await services.auth.login(credentials.username, credentials.password);
			setLoggedIn(true);
		});

	const handleKeyPress = async (event) => {
		if (canLogin && event.code === "Enter") {
			await handleLogin();
		}
	};

	return (
		<>
			<LoadingSpinner isLoading={isLoading} />

			<div className="box login-box-border">
				<div className="box">
					<img src={logo} alt="Centivo Logo" />
				</div>
				<div className="title has-text-grey is-5">
					Please enter your email and password
				</div>

				<form onKeyPress={handleKeyPress}>
					<div className="field">
						<div className="control">
							<input
								className="input is-large is-primary2-color-underline"
								type="email"
								placeholder="Email"
								autoComplete="email"
								value={credentials.username}
								onChange={handleChange("username")}
							/>
						</div>
					</div>
					<div className="field">
						<div className="control">
							<input
								className="input is-large is-primary2-color-underline"
								type="password"
								placeholder="Password"
								autoComplete="current-password"
								value={credentials.password}
								onChange={handleChange("password")}
							/>
						</div>
					</div>
				</form>
				<BigButton onClick={handleLogin} disabled={!canLogin}>
					Login
				</BigButton>
			</div>
		</>
	);
};
