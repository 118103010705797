import { toast } from "react-toastify";
import { voidFn } from "./voidFn";

export const tryRunAsyncFactory =
	(onLoading = voidFn, onError = null) =>
	async (fn) => {
		try {
			onLoading(true);
			await fn();
		} catch (e) {
			if (onError) {
				onError(e);
			} else {
				toast.error("Oh snap! Something unexpected happened");
			}
			console.error(e);
		} finally {
			onLoading(false);
		}
	};
