/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import React, { useState } from "react";
import DatePicker from "react-datepicker";

const labelStyle = css`
	position: absolute;
	left: 0;
	top: -16px;
	width: 100%;
	font-size: smaller;
`;

const pickerStyle = css`
	margin-right: 0.25rem;
	width: 140px;
`;
const DateInput = React.forwardRef(
	({ value, onClick, onChange, label }, ref) => (
		<>
			<button
				className="button is-primary is-full-width"
				defaultValue={value}
				onClick={onClick}
			>
				<span className="icon">
					<i className="fas fa-calendar-alt" />
				</span>
				<span>{value}</span>
			</button>
			<label css={labelStyle}>{label}</label>
		</>
	)
);

export const GenericDatePicker = ({
	dateFrom,
	dateTo,
	onChange,
	disabled = false,
	startLabel = "Start Date",
	endLabel = "End Date",
}) => {
	const [startDate, setStartDate] = useState(dateFrom);
	const [endDate, setEndDate] = useState(dateTo);

	const handleStartChange = (date) => {
		if (checkDateRange(date, endDate)) {
			setStartDate(date);
			onChange({ start: date, end: endDate });
		}
	};

	const handleEndChange = (date) => {
		if (checkDateRange(startDate, date)) {
			setEndDate(date);
			onChange({ start: startDate, end: date });
		}
	};

	const checkDateRange = (start, end) => {
		if (start >= end.setHours(0, 0, 0, 0)) {
			alert("Date range: start date must be before end date!");
			return false;
		}
		return true;
	};

	return (
		<>
			<div css={pickerStyle}>
				<DatePicker
					selected={startDate}
					onChange={handleStartChange}
					selectsStart
					disabled={disabled}
					startDate={startDate}
					endDate={endDate}
					customInput={<DateInput label={startLabel} />}
				/>
			</div>
			<div css={pickerStyle}>
				<DatePicker
					selected={endDate}
					onChange={handleEndChange}
					selectsEnd
					disabled={disabled}
					startDate={startDate}
					endDate={endDate}
					minDate={startDate}
					customInput={<DateInput label={endLabel} />}
				/>
			</div>
		</>
	);
};
