import React, { useEffect, useState } from "react";
import RotateLoader from "react-spinners/RotateLoader";
import styles from "../../styles";
import { TileActions } from "./TileActions";
import api from "../../services/api";
import { DragDrop } from "./DragDrop";
import { JsonTree } from "./JsonTree";
import { toast } from "react-toastify";
import { tryRunAsyncFactory } from "../../utils/tryRunAsyncFactory";
import { IngestionLogTable } from "./IngestionLog";
import subDays from "date-fns/subDays";

const VSpacer = () => <div className="mt-6" />;

const DomainTypes = {
	Provider: "PROVIDER",
	Facility: "FACILITY",
	Medical: "MEDICAL",
	ContractData: "CONTRACT_DATA",
	VedaProvider: "veda-provider",
	VedaFacility: "veda-facility",
	InterimProvider: "interim-provider",
	InterimFacility: "interim-facility",
};

const ValidationActions = [
	{ text: "Validate Interim Provider File", type: DomainTypes.InterimProvider },
	{ text: "Validate Interim Facility File", type: DomainTypes.InterimFacility },
	{ text: "Validate Provider File", type: DomainTypes.Provider },
	{ text: "Validate VEDA Provider File", type: DomainTypes.VedaProvider },
	{ text: "Validate Facility File", type: DomainTypes.Facility },
	{ text: "Validate VEDA Facility File", type: DomainTypes.VedaFacility },
	{ text: "Validate MedicalNeighborhood File", type: DomainTypes.Medical },
	{ text: "Validate Contract Data File", type: DomainTypes.ContractData },
];

const SubmissionActions = [
	{ text: "Submit Interim Provider File", type: DomainTypes.InterimProvider },
	{ text: "Submit Interim Facility File", type: DomainTypes.InterimFacility },
	{ text: "Submit Provider File", type: DomainTypes.Provider },
	{ text: "Submit VEDA Provider File", type: DomainTypes.VedaProvider },
	{ text: "Submit Facility File", type: DomainTypes.Facility },
	{ text: "Submit VEDA Facility File", type: DomainTypes.VedaFacility },
	{ text: "Submit MedicalNeighborhood File", type: DomainTypes.Medical },
	{ text: "Submit Contract Data File", type: DomainTypes.ContractData },
];

const IngestionActions = [
	{ text: "Show Provider Logs", type: DomainTypes.Provider },
	{ text: "Show Facility Logs", type: DomainTypes.Facility },
];

export default () => {
	const [isLoading, setIsLoading] = useState(false);
	const [validationResult, setValidationResult] = useState(null);
	const [isDocumentAccepted, setIsDocumentAccepted] = useState(false);
	const [selectedFile, setSelectedFile] = useState({ name: "", data: null });
	const [logData, setLogData] = useState(null);
	const [logType, setLogType] = useState(null);
	const [logDateRange, setLogDateRange] = useState({
		start: subDays(new Date(), 30),
		end: new Date(),
	});
	const [isFileNameValid, setFileNameValid] = useState(true);

	useEffect(() => {
		if (!isFileNameValid) {
			toast.error(
				"VEDA file name cannot include words like 'Focus'. Please rename file appropriately!"
			);
		}
	}, [isFileNameValid]);

	const tryRunAsync = tryRunAsyncFactory(setIsLoading);

	const hasSelectedFile = () => selectedFile.data && selectedFile.name;

	const checkFileName = (file) => {
		let fileName = file.name.toLowerCase();
		if (
			fileName.includes("focus") ||
			fileName.includes("focus - providers") ||
			fileName.includes("focus - facilities")
		) {
			setFileNameValid(false);
		} else {
			setFileNameValid(true);
		}
	};

	const handleValidateFile = async (type) => {
		if (!hasSelectedFile()) {
			return;
		}

		await tryRunAsync(async () => {
			setValidationResult(null);
			let { resultList, isThreshold } = await api.validateFile(
				selectedFile.name,
				selectedFile.data,
				type
			);
			if (isThreshold) {
				if (type === "interim-provider" || type === "interim-facility") {
					if (resultList && resultList.length > 0) {
						const nonAddressError = resultList.find((record) => {
							return !(
								record.error
									?.toLowerCase()
									.includes("verify practice address") ||
								record.error?.toLowerCase().includes("verify payment address")
							);
						});
						/*	Disable interim submit file buttons when non address validation error is detected */
						if (!nonAddressError) {
							isThreshold = false;
							toast.warn("Verify Address before submitting file!");
						} else {
							toast.error("Validation Errors in File!");
						}
					}
				} else {
					toast.warn("Document has too many issues and cannot be accepted");
				}
			} else {
				toast.success("Document is acceptable");
			}
			setIsDocumentAccepted(!isThreshold);
			setValidationResult(resultList);
		});
	};

	const handleSubmitFile = async (type) => {
		if (!hasSelectedFile()) {
			return;
		}

		await tryRunAsync(async () => {
			const response = await api.submitFile(
				selectedFile.name,
				selectedFile.data,
				type
			);
			if (response !== null) {
				toast.success("Document submitted successfully");
			} else {
				toast.warn("Oh snap! Submission failure");
			}
		});
	};

	const handleDroppedFile = (file) => {
		setSelectedFile(file);
		checkFileName(file);
	};

	const handleFetchLogs = async (type) => {
		setLogType(type);
	};

	const handleReset = () => {
		setIsLoading(false);
		setLogData(null);
		setValidationResult(null);
		setIsDocumentAccepted(false);
		setSelectedFile({ name: "", data: null });
		setFileNameValid(true);
	};

	const handleLogDateChange = (dateRange) => {
		setLogDateRange(dateRange);
	};

	useEffect(() => {
		if (!logType) return;

		const fetchLogs = async (type) =>
			await tryRunAsync(async () => {
				setLogData(null);
				const data = await api.getIngestionLogs(
					type.toLowerCase(),
					logDateRange.start,
					logDateRange.end
				);
				setLogData(data);
			});

		fetchLogs(logType);

		// eslint-disable-next-line
	}, [logType, logDateRange]);

	return (
		<div>
			<RotateLoader
				css={styles.override}
				size={30}
				margin={30}
				color={"#ED008B"}
				loading={isLoading}
			/>
			<div id="topDivider" className="container is-margin-top">
				<TileActions
					title="Validate Files"
					actions={ValidationActions}
					onAction={handleValidateFile}
					disabled={!hasSelectedFile() || !isFileNameValid}
				/>
				{validationResult && (
					<JsonTree json={validationResult} title="Validation Result" />
				)}
				<VSpacer />
				<TileActions
					title="Submit Files"
					actions={SubmissionActions}
					onAction={handleSubmitFile}
					disabled={!hasSelectedFile() || !isDocumentAccepted}
				/>

				<VSpacer />
				<DragDrop onFile={handleDroppedFile} onReset={handleReset} />

				<VSpacer />
				<TileActions
					title="Ingestion Logs"
					actions={IngestionActions}
					onAction={handleFetchLogs}
				/>
				<VSpacer />
				{logData && (
					<IngestionLogTable
						logs={logData}
						dateFrom={logDateRange.start}
						dateTo={logDateRange.end}
						onDateChange={handleLogDateChange}
					/>
				)}
			</div>
		</div>
	);
};
