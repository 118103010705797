import React, { useEffect, useState } from "react";
import startCase from "lodash.startcase";
import { voidFn } from "../../../utils/voidFn";

const DeletableTag = ({ text, onDelete }) => {
	return (
		<div className="control">
			<div className="tags has-addons">
				<span className="tag">{text}</span>
				{/*eslint-disable-next-line*/}
				<a className="tag is-delete is-danger" onClick={() => onDelete(text)} />
			</div>
		</div>
	);
};

export const TagListFormField = ({
	name,
	formData = {},
	readonly = false,
	onChange = () => voidFn,
	customLabel = undefined,
}) => {
	const [value, setValue] = useState("");
	const [tags, setTags] = useState(new Set());

	useEffect(() => {
		if (formData[name]) {
			tokenize(formData[name].value || "");
		}
	}, [formData]);

	const handleOnKeyPress = (event) => {
		if (event.key === " " || event.key === ";" || event.key === ",") {
			event.preventDefault();
			if (!value.trim()) {
				return;
			}
			const newTags = new Set(tags.add(value.trim()));
			setTags(newTags);
			setValue("");
			propagateChanges(newTags);
		}
	};

	const propagateChanges = (updatedTags) => {
		const value = Array.from(updatedTags).join(",");
		onChange(name)(value);
	};

	const tokenize = (text, applyChanges) => {
		const multitags = text.replace(/[;,]/gi, " ").split(" ");
		if (multitags.length > 0) {
			const newTags = new Set(tags);
			multitags.forEach((t) => t && newTags.add(t));
			setTags(newTags);
			setValue("");
			if (applyChanges) {
				propagateChanges(newTags);
			}
		} else {
			setValue("");
		}
	};

	const handleOnChange = (event) => {
		if (event.target.value.search(/[;,\s+]/gi) < 0) {
			setValue(event.target.value);
			return;
		}
		tokenize(event.target.value, true);
	};

	const handleOnBlur = (event) => {
		const value = event.target.value.trim();
		if (!value) {
			return;
		}

		const newTags = new Set(tags.add(value));
		setTags(newTags);
		setValue("");
		propagateChanges(newTags);
	};

	const handleDeleteTag = (tag) => {
		const newTags = new Set(tags);
		newTags.delete(tag);
		setTags(newTags);
		propagateChanges(newTags);
	};

	const label = customLabel || startCase(name);
	return (
		<div className="field is-full-width">
			<div className="control">
				<label className="is-size-6 has-text-grey">
					{label}
					<input
						className="input is-medium"
						type="text"
						placeholder={label}
						value={value}
						onKeyPress={handleOnKeyPress}
						onChange={handleOnChange}
						onBlur={handleOnBlur}
						disabled={readonly}
					/>
				</label>
				<div className="field is-grouped is-grouped-multiline mt-1">
					{Array.from(tags).map((v) => (
						<DeletableTag key={v} text={v} onDelete={handleDeleteTag} />
					))}
				</div>
			</div>
		</div>
	);
};
