import React, { useRef, useState } from "react";
import { Form } from "../../components/Form";
import { IconButton } from "../../components/IconButton";

const DEFAULT_SCHEMA = {
	title: "Doctor Data",
	description: "Edit the provided Doctor Data.",
	type: "object",
	properties: {},
};

const genFormProps = (
	data,
	defaultSchema = DEFAULT_SCHEMA,
	ignoreAttributes = [
		"npi",
		"network",
		"city",
		"state",
		"zipCode",
		"address1",
		"address2",
		"practiceName",
		"minAge",
		"maxAge",
		"version",
		/* below fields to be editable - implemented in CENG-1248
		"terminationDate",
		"effectiveDate",
		"isAcceptingNewPatients",
		*/
	],
	keyCustomConfig = {
		providerToolNote: {
			sort: -1,
			title: "Note",
			type: "text",
			valueIfNull: "",
		},
	}
) => {
	if (data == null) {
		return defaultSchema;
	}

	const properties = {};
	const dataKeys = Object.keys(data).sort();
	for (let key of dataKeys) {
		if (
			key.toLowerCase().includes("search") ||
			key.toLowerCase().includes("#") ||
			key.toLowerCase().includes("geohash") ||
			key === "created" ||
			key === "modified" ||
			key === "uuid" ||
			key === "lat" ||
			key === "long"
		) {
			continue;
		}
		let value = data[key];

		properties[key] = {
			type: "string",
			title: `${key}:`,
			value: "",
		};

		if (value == null) {
			if (keyCustomConfig[key]?.valueIfNull !== undefined) {
				value = keyCustomConfig[key].valueIfNull;
			} else {
				properties[key] = {
					isDisabled: false,
				};
				continue;
			}
		}
		if (typeof value === "number") {
			properties[key].type = "number";
		} else if (typeof value === "string") {
			properties[key].type = "string";
		} else if (typeof value === "boolean") {
			properties[key].type = "boolean";
		}
		properties[key].value = value;
		if (ignoreAttributes.includes(key)) {
			properties[key].isDisabled = true;
		}
		properties[key] = { ...properties[key], ...(keyCustomConfig[key] ?? {}) };
	}
	const copySchema = JSON.parse(JSON.stringify(defaultSchema));
	copySchema.properties = { ...copySchema.properties, ...properties };
	return copySchema;
};

const EditItemModal = ({
	data,
	isActive = false,
	onModalClose,
	onSubmit = () => {},
	defaultSchema,
	ignoreAttributes,
}) => {
	let changeData = data;

	const cardBodyRef = useRef(null);

	const handleCancel = (e) => {
		e.preventDefault();
		onModalClose();
		cardBodyRef.current.scrollTo(0, 0);
	};

	const handleSubmit = () => {
		onModalClose();
		onSubmit(changeData);
		cardBodyRef.current.scrollTo(0, 0);
	};

	const onChange = (data) => {
		changeData = data;
	};

	return (
		<div className={`modal ${isActive ? "is-active" : ""}`}>
			<div className="modal-background" />
			<div className="modal-card">
				<div className="modal-card-head">
					<p className={"modal-card-title is-marginless has-text-centered"}>
						Edit Item
					</p>
					<button className="delete" onClick={handleCancel} />
				</div>
				<section className="modal-card-body" ref={cardBodyRef}>
					<div>
						<Form
							schema={genFormProps(data, defaultSchema, ignoreAttributes)}
							onChange={(data) => onChange(data)}
						>
							<div>
								<button
									style={{ marginTop: "20px", width: "100%" }}
									className="button is-primary"
									type="submit"
									onClick={handleSubmit}
								>
									Submit
								</button>
							</div>
						</Form>
					</div>
				</section>
			</div>
		</div>
	);
};

export const EditItemContainer = ({
	data,
	ignoreAttributes,
	onSubmit,
	title,
}) => {
	const [modalOpen, setModalOpen] = useState(false);

	const onModalOpen = () => setModalOpen(true);
	const onModalClose = () => setModalOpen(false);

	return (
		<>
			<IconButton onClick={onModalOpen} title="Edit" iconName="fa-edit" />
			<EditItemModal
				data={data}
				ignoreAttributes={ignoreAttributes}
				isActive={modalOpen}
				onModalClose={onModalClose}
				onSubmit={onSubmit}
				defaultSchema={{
					title: `${title} Data`,
					description: `Edit the provided ${title} Data.`,
					type: "object",
					properties: {
						providerToolNote: {
							title: "Notes",
							type: "text",
							sort: -1,
							valueIfNull: "",
						},
					},
				}}
			/>
		</>
	);
};
